
import customMapState from '@/helpers/mapHelper';
import { peerMatchThresholdsValidation } from '@/helpers/peerMatchThresholds';
import { IRootState } from '@/models/IRootState';
import Vue from 'vue';
import { mapGetters } from 'vuex';

const VueComponent = Vue.extend({
  data() {
    return {
      internalValue: false,
      internalLockedType: '',
      internalValueInt: undefined,
    }
  },
  computed: {
    ...customMapState({
      donateAnonymously: (state: IRootState) => state.donation.donateAnonymouslyFlag,
      allowConditionalDonations: (state: IRootState) => state.donation.donationParams.data?.attributes.config.allow_conditional_donations,
    }),
    ...mapGetters({
      originAmount: 'originAmount',
    }),

    minAmountThreshold(): number {
      return this.allowConditionalDonations?.min_amount_threshold;
    },
    maxAmountThreshold(): number {
      return this.allowConditionalDonations?.max_amount_threshold;
    },

    hasThresholdsError(): string {
      const validation = peerMatchThresholdsValidation(
        {
          minAmountThreshold: this.minAmountThreshold,
          maxAmountThreshold: this.maxAmountThreshold,
        },
        this.originAmount,
      );

      if (validation.valid) {
        return '';
      }

      return validation.data.message;
    },
  },
  watch: {
    hasThresholdsError() {
      this.onLockedChange('', false);
    },
    donateAnonymously(v) {
      if (v) {
        this.onLockedChange('', false);
      }
    },
  },
  methods: {
    resetLocked() {
      this.internalValue = false;
      this.internalLockedType = '';

      this.$store.commit('setLocked', false);
      this.$store.commit('setLockedType', '');
      this.$store.commit('deleteLockedVarInt');
    },
    onLockedChange(type: string, value: boolean) {
      this.resetLocked();

      switch (type) {
        case 'team_reach_x':
          this.internalValue = value;
          this.internalLockedType = value ? 'team_reach_x' : '';

          this.$store.commit('setLocked', this.internalValue);
          this.$store.commit('setLockedType', this.internalLockedType);
          break;

        case 'peer_match':
          this.internalValue = value;
          this.internalLockedType = value ? 'peer_match' : '';
          this.internalValueInt = undefined;

          this.$store.commit('setLocked', this.internalValue);
          this.$store.commit('setLockedType', this.internalLockedType);
          this.$store.commit('deleteLockedVarInt');
          break;

        default:
          this.resetLocked();
          break;
      }
    },
    onLockedVarIntInput(event: Event) {
      const { value } = event.target as HTMLInputElement;
      const number = Number(value);
      if (Number.isNaN(number)) {
        return
      }

      (this.internalValueInt as unknown as number) = number;
      this.$store.commit('setLockedVarInt', number);
    },
  },
});

export default class AllowConditionalDonations extends VueComponent {}
