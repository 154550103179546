
import Vue, { PropType } from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import { getMatchedDonationId } from '@/helpers/getUrlParameters';

const VueComponent = Vue.extend({
  props: {
    internalValue: {
      type: Boolean,
      default: false,
    },
    internalLockedType: {
      type: String,
      default: '',
    },
    onLockedChange: {
      type: Function as PropType<(type: string, value: boolean) => void>,
      required: true,
    },
    hasThresholdsError: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...customMapState({
      allowConditionalDonations: (state: IRootState) => state.donation.donationParams.data?.attributes.config.allow_conditional_donations,
    }),
    thresholdsDisabled(): boolean {
      return !!this.hasThresholdsError;
    },
    show(): boolean {
      const matchedDonationId = getMatchedDonationId();
      if (matchedDonationId) {
        return false;
      }
      return this.allowConditionalDonations?.value && this.allowConditionalDonations?.types_supported.includes('peer_match');
    },
  },
  methods: {
    onChange(event: Event) {
      this.onLockedChange('peer_match', (event.target as HTMLInputElement).checked);
    },
  },
});

export default class PeerMatchControl extends VueComponent {}
