import Vue from 'vue';

export function peerMatchThresholdsValidation(ctx: {minAmountThreshold: number, maxAmountThreshold: number}, value: any) {
  const amount = Number(value);
  if (Number.isNaN(amount)) return { valid: false, data: { message: 'Amount is not a number' } };

  // allow setting the minimum / maximum threshold for this type of donation.
  // We want to avoid a number that is too small to avoid spam and sometimes we want to
  // avoid an amount that is too big because it’s too difficult to be matched by someone else.
  if (ctx.minAmountThreshold && ctx.maxAmountThreshold) {
    const message = Vue.i18n.translate(
      'donation.peer_match_description_not_met_min_max',
      'Your donation amount is below the minimum threshold of {minAmountThreshold} and above the maximum threshold of {maxAmountThreshold}.',
      {
        minAmountThreshold: ctx.minAmountThreshold,
        maxAmountThreshold: ctx.maxAmountThreshold,
      },
    )!;
    const valid = ctx.minAmountThreshold <= amount && ctx.maxAmountThreshold >= amount;
    return {
      valid,
      data: {
        message,
      },
    };
  }
  if (ctx.minAmountThreshold) {
    return {
      valid: amount >= ctx.minAmountThreshold,
      data: {
        message: Vue.i18n.translate(
          'donation.peer_match_description_not_met_min',
          'Your donation amount is below the minimum threshold of {minAmountThreshold}.',
          {
            minAmountThreshold: ctx.minAmountThreshold,
          },
        )!,
      },
    };
  }
  if (ctx.maxAmountThreshold) {
    return {
      valid: amount <= ctx.maxAmountThreshold,
      data: {
        message: Vue.i18n.translate(
          'donation.peer_match_description_not_met_max',
          'Your donation amount is above the maximum threshold of {maxAmountThreshold}.',
          {
            maxAmountThreshold: ctx.maxAmountThreshold,
          },
        )!,
      },
    };
  }

  return {
    valid: true,
    data: {
      message: '',
    },
  };
}

export default {}
