
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import getCookie from '@/helpers/cookie';
import features from '@/constants/features';
import { getDataForAnalytics } from '@/helpers/analytics';
import { triggerGTMStepEvent } from '@/helpers/googleTagManager';
import { initBeforeUnloadListener, removeBeforeUnloadListener } from '@/before-unload';
import { IRootState } from '../models/IRootState';
import { IDonateExtendedData } from './components-models/DonateExtended.model';
import PeerMatchControl from './subcomponents/PeerMatchControl.vue';
import LoginPanel from './subcomponents/LoginPanel.vue';
import DonationAmount from './subcomponents/DonationAmount.vue';
import DonationAmountNew from './subcomponents/DonationAmountNew.vue';
import TipBlockNew from './subcomponents/TipBlockNew.vue';
import TipBlockRangePick from './subcomponents/TipBlockRangePick.vue';
import PersonalData from './subcomponents/PersonalData.vue';
import DonateWith from './subcomponents/DonateWith.vue';
import ExtraInfo from './subcomponents/ExtraInfo.vue';
import AddressData from './subcomponents/AddressData.vue';
import PaymentType from './subcomponents/PaymentType.vue';
import PaymentData from './subcomponents/PaymentData.vue';
import CreateAccount from './subcomponents/CreateAccount.vue';
import Confirmation from './subcomponents/Confirmation.vue';
import ConfirmationNew from './subcomponents/ConfirmationNew.vue';
import Success from './subcomponents/Success.vue';
import AppGoalAsItem from './subcomponents/GoalAsItem.vue';
import PreFormSteps from './subcomponents/PreFormSteps.vue';
import LevelsAboveAmount from './subcomponents/LevelsAboveAmount.vue';
import StepsBullets from './subcomponents/StepsBullets.vue';
import PhoneField from './subcomponents/PhoneField.vue';
import PaymentCustomHeader from './subcomponents/PaymentCustomHeader.vue';
import PaymentInfoSection from './subcomponents/PaymentInfoSection.vue';
import CantMakePaymentAlert from './subcomponents/CantMakePaymentAlert.vue';
import OrgLogo from './subcomponents/OrgLogo.vue';
import ConfirmationStep1 from './subcomponents/ConfirmationStep1.vue';
import Upsell from './subcomponents/Upsell.vue';
import TaxGiftForm from './subcomponents/taxgift/TaxGiftForm.vue';
import AppCustomGoals from './subcomponents/CustomGoals.vue';
import LdsSpinner from './LdsSpinner.vue';
import AlertGBPSelected from './subcomponents/AlertGBPSelected.vue';
import TeamReachXControl from './subcomponents/TeamReachXControl.vue';
import AllowConditionalDonations from './subcomponents/AllowConditionalDonations.vue';

const { searchParams } = new URL(window.location.href);

const VueComponent = Vue.extend({
  components: {
    appLoginPanel: LoginPanel,
    appDonationAmount: DonationAmount,
    appDonationAmountNew: DonationAmountNew,
    appDonationTipNew: TipBlockNew,
    appDonationTipRangePick: TipBlockRangePick,
    appPersonalData: PersonalData,
    appDonateWith: DonateWith,
    appExtraInfo: ExtraInfo,
    appAddressData: AddressData,
    appPaymentType: PaymentType,
    appPaymentData: PaymentData,
    appCreateAccount: CreateAccount,
    appConfirmation: Confirmation,
    appConfirmationNew: ConfirmationNew,
    appSuccess: Success,
    appTaxGift: TaxGiftForm,
    AppGoalAsItem,
    PreFormSteps,
    LevelsAboveAmount,
    StepsBullets,
    PhoneField,
    PaymentCustomHeader,
    PaymentInfoSection,
    CantMakePaymentAlert,
    OrgLogo,
    ConfirmationStep1,
    Upsell,
    AppCustomGoals,
    LdsSpinner,
    AlertGBPSelected,
    PeerMatchControl,
    TeamReachXControl,
    AllowConditionalDonations,
  },
  data(): IDonateExtendedData {
    return {
      submitting: false,
      isPaymentType: true,
      paymentBy: 'card',
      features,
    };
  },

  computed: {
    ...customMapState({
      // 0 - pre form steps, 1 - initial form, 2 - confirmation, 3 - thank you
      stepFlag: (state: IRootState) => state.donation.stepFlag,

      paymentMethod: (state: IRootState) => state.donation.paymentMethod,

      isLoadCampaignParams: (state: IRootState) => state.donation.isLoadCampaignParams,
      campaignParamsLoading: (state: IRootState) => state.donation.campaignParamsLoading,

      loadingLang: (state: IRootState) => state.translation.loadLang,

      loadTranslation: (state: IRootState) => state.donation.loadTranslation,

      addressConfig: (state: IRootState) => state.donation.donationParams.data.attributes.address_config,

      showLogin: (state: IRootState) => state.donation.donationParams?.data?.attributes.config.show_login,

      hasAdditionalFields: (state: IRootState) => !!state.donation.donationParams.data.attributes.config.custom_data.length,

      hideLoader: (state: IRootState) => state.donation.error?.hideLoader,

      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,

      phoneConfig: (state: IRootState) => state.donation.donationParams.data.attributes.config.phone_config,
      mailReceiptConfig: (state: IRootState) => state.donation.donationParams.data?.attributes.config.mail_receipt_config,
      donationCurrency: (state: IRootState) => state.donation.donationData.attributes.currency,
      giftAid: (state: IRootState) => state.donation.donationData.attributes.gift_aid,
      savedCreditCard: (state: IRootState) => state.donation.savedCreditCard,
      isDialogVisible: (state: IRootState) => state.donation.dialog.visible,
      allowConditionalDonations: (state: IRootState) => state.donation.donationParams.data?.attributes.config.allow_conditional_donations,
    }),

    ...mapGetters({
      isGatewayDisabled: 'isGatewayDisabled',
      isMeshulamGooglePayDisabled: 'isMeshulamGooglePayDisabled',
      isCanMakePayment: 'isCanMakePayment',
      hideAmountInput: 'hideAmountInput',
      confirmationStep1: 'confirmationStep1',
      uiMode: 'uiMode',
      tipOnConfirmStep: 'tipOnConfirmStep',
    }),

    formLoadCondition() {
      return this.isLoadCampaignParams && !this.loadingLang;
    },

    showLoadingSpinner(): boolean {
      const cond1 = !this.isLoadCampaignParams && this.loadingLang;
      const cond2 = this.campaignParamsLoading;

      return (cond1 || cond2) && !this.hideLoader;
    },

    mailReceipt: {
      get(): boolean {
        return this.$store.state.donation.donationData.attributes.mail_receipt;
      },
      set(value: boolean) {
        this.$store.commit('setMailReceipt', value);
      },
    },

    showMailReceipt(): boolean {
      if (['israeltoremet'].includes(this.paymentMethod) && ['cad'].includes(this.donationCurrency)) {
        return false;
      }
      return this.mailReceiptConfig?.gateways?.includes(this.paymentMethod);
    },

    addressIsVisible(): boolean {
      const {
        available, required_for_gateways, gateways, hide_for_gateways,
      } = this.addressConfig;

      if (this.showMailReceipt) {
        return this.mailReceipt || this.giftAid
      }

      if (!this.giftAid && this.savedCreditCard) {
        return false;
      }

      if (available && hide_for_gateways?.includes(this.paymentMethod) && !this.giftAid) {
        return false;
      }

      return available || Boolean(required_for_gateways?.includes(this.paymentMethod)) || Boolean(gateways?.includes(this.paymentMethod)) || this.giftAid;
    },

    gatewayInfoSectionPosition(): string {
      return this.config.gateway_info_section_position;
    },

    gatewayInfoSectionPositionValid(): boolean {
      return ['under-continue-button'].includes(this.gatewayInfoSectionPosition);
    },
  },

  watch: {
    stepFlag(value: number) {
      if (value === 2) this.submitting = false;

      if (value <= 2) {
        const state = this.$store.state as IRootState;
        const { teams, currency } = getDataForAnalytics(state);

        triggerGTMStepEvent({ step: value, teams, currency });
      }

      if (value === 3) {
        removeBeforeUnloadListener();
      }
    },
    showLogin: {
      handler(value: boolean) {
        const token = searchParams.get('token') || getCookie('jwt_token');

        if (token && value) {
          this.setTokenIfValid(token);
        }
      },
      immediate: true,
    },
    paymentMethod: {
      handler(value) {
        if (this.showMailReceipt && ['israeltoremet'].includes(value) && ['cad'].includes(this.donationCurrency)) {
          this.mailReceipt = true;
        } else {
          this.mailReceipt = false;
        }
      },
      immediate: true,
    },
    hideAmountInput: {
      handler(value) {
        if (value?.setAmount) {
          this.$store.commit('setOriginAmount', value.setAmount);
          this.$store.commit('setDonationAmount', value.setAmount);
          this.$store.commit('setTotalAmount', 0);
        } else {
          this.$store.commit('setTotalAmount', 0);
          this.$store.commit('setOriginAmount', 0);
          this.$store.commit('setDonationAmount', 0);
        }
      },
      immediate: true,
    },
    formLoadCondition: {
      handler(value) {
        if (!value) return;
        this.$nextTick(() => {
          const form = this.$refs.donateFormExtended as HTMLFormElement;
          const formElements = form.elements;
          const hasNonEmptyValues = Array.from(formElements)
            .map(el => {
              const formEl = el as HTMLInputElement | HTMLSelectElement;
              return formEl.value;
            })
            .some(v => v.trim() !== '');

          if (hasNonEmptyValues) {
            this.sendToParent({ type: 'registered-before-unload' });
            initBeforeUnloadListener();
          }
        });
      },
    },
    isDialogVisible: {
      handler(value) {
        if (value) {
          removeBeforeUnloadListener();
        } else {
          initBeforeUnloadListener();
        }
      },
    },
  },

  methods: {
    ...mapActions({
      setTokenIfValid: 'setTokenIfValid',
    }),

    sendToParent(data: Record<string, unknown>) {
      const inIframe = window.self !== window.parent;

      if (inIframe) {
        window.parent.postMessage(data, '*');
      }
    },

    submit(): void {
      const validationArray = this.$children.map((child: any) => child.$validator.validateAll());

      Promise.all(validationArray)
        .then(async formsList => {
          if (formsList.indexOf(false) === -1) {
            if (this.hasAdditionalFields) {
              this.$root.$emit('extraInfoSetData');
            }
            // emit root event for set donation included gateway params
            const error = await new Promise(resolve => {
              try {
                this.$root.$emit('setDonationGatewayParams', resolve);
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
              }

              const skip = ['cardknox', 'banquest', 'blink-fidelipay-direct', 'walletdoc-direct', 'authorize', 'payarc'];
              if (!skip.includes(this.paymentMethod)) {
                resolve(false);
              }
            });

            if (error) throw error;
            // go to confirmation page if all fields is filled
            this.$store.commit('setStepFlag', 2);
            window.parent.postMessage({ type: 'continue-to-next-step' }, '*');
          } else {
            this.submitting = false;

            const vFieldErrors = this.vErrors.items.reduce(
              (res: any, el: any) => {
                if (!res.fields.includes(el.field)) {
                  res.fields.push(el.field);
                }

                return res;
              },
              { fields: [] as string[] },
            );

            this.$store.dispatch('addError', {
              title: `${this.$t('donation.submit_form_error_title')}`,
              text: `${this.$t('donation.check_fields_error')} ${vFieldErrors.fields.map((f: string) => ` ${this.$t(`donation.field_${f}`, f)}`)}`,
            });
          }
          return true;
        })
        .catch(err => {
          this.submitting = false;
          this.$store.dispatch('addError', {
            title: err.title || `${this.$t('donation.submit_form_error_title')}`,
            text: err.text || err,
          });
        });
    },

    nextStep(): void {
      if (this.paymentMethod) {
        this.submitting = true;
        this.submit();
      } else {
        this.$store.dispatch('addError', {
          title: this.$t('donation.no_payment_method_error_title', 'No Payment Method'),
          text: this.$t('donation.no_payment_method_error_msg', 'Please select another currency'),
        });
      }
    },

    stepBack(): void {
      this.$store.commit('setStepFlag', 1);
    },
  },
});
export default class DonateExtended extends VueComponent {}
