const pathname: string = window.location.href;
export const { searchParams } = new URL(pathname);

export const get = (name: string): string => (searchParams.get(name) || '');

export const getCampaignId = () => {
  if (window.location.host === 'translate.googleusercontent.com') {
    const url = new URL(get('u'));
    return url.pathname.substring(1);
  }

  return window.location.pathname.substring(1);
}

export const getTeamId = () => get('team_id')

export const getMatchedDonationId = () => Number(get('matched_donation_id')) || 0;

export default { searchParams, get, getCampaignId };
