
import Vue from 'vue';
import axios from 'axios';
import urls from '@/constants/urls';
import customMapState from '@/helpers/mapHelper';
import { getMatchedDonationId } from '@/helpers/getUrlParameters';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  data() {
    return {
      isSuccess: false,
      error: '',
      sending: false,
      form: {
        email: '',
      },
    };
  },

  computed: {
    ...customMapState({
      dId: (state: IRootState) => state.donation.donationId,
      allowConditionalDonations: (state: IRootState) => state.donation.donationParams.data?.attributes.config.allow_conditional_donations,
    }),
    show(): boolean {
      const matchedDonationId = getMatchedDonationId();
      if (matchedDonationId) {
        return false;
      }
      return this.allowConditionalDonations?.value;
    },
  },

  methods: {
    postDonationSuccessInviteFriends(email: string) {
      return axios.post(urls.postDonationSuccessInviteFriends(this.dId), { email });
    },
    async onSubmit() {
      this.sending = true;

      try {
        await this.postDonationSuccessInviteFriends(this.form.email);
      } catch (e: any) {
        this.error = `${e.response.data.errors?.[0].title}: ${e.response.data.errors?.[0].detail}`;
        return;
      } finally {
        this.sending = false;
      }

      this.isSuccess = true;
    },
  },
});
export default class InviteFriendsToMatchDonation extends VueComponent {}
