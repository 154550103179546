
import Vue, { PropType } from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import { getMatchedDonationId } from '@/helpers/getUrlParameters';

const VueComponent = Vue.extend({
  props: {
    internalValue: {
      type: Boolean,
      default: false,
    },
    internalLockedType: {
      type: String,
      default: '',
    },
    internalValueInt: {
      type: [Number, Object],
      default: undefined,
    },
    onLockedChange: {
      type: Function as PropType<(type: string, value: boolean) => void>,
      required: true,
    },
    onLockedVarIntInput: {
      type: Function,
      default: () => {},
    },
    hasThresholdsError: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...customMapState({
      allowConditionalDonations: (state: IRootState) => state.donation.donationParams.data?.attributes.config.allow_conditional_donations,
      firstSelectedTeam: (state: IRootState) => state.donation.first_selected_team,
    }),
    show(): boolean {
      const matchedDonationId = getMatchedDonationId();
      if (matchedDonationId) {
        return false;
      }

      return Object.keys(this.firstSelectedTeam || {}).length > 0
      && this.allowConditionalDonations?.value
      && this.allowConditionalDonations?.types_supported.includes('team_reach_x');
    },
    thresholdsDisabled(): boolean {
      return !!this.hasThresholdsError;
    },
  },
  methods: {
    vErrorsHas(field:string, rule: string) {
      return this.vErrors.items.find((el: any) => el.field === field && el.rule === rule);
    },
    onChange(event: Event) {
      this.onLockedChange('team_reach_x', (event.target as HTMLInputElement).checked);
    },
  },
});

export default class TeamReachXControl extends VueComponent {}
