
import { Component, Vue, Watch } from 'vue-property-decorator';

import customMapState from './helpers/mapHelper';
import { injectGoogleTagManager } from './helpers/googleTagManager'
import { get, getCampaignId } from './helpers/getUrlParameters';
import { getDataForAnalytics, getGAClientID } from './helpers/analytics';
import { IRootState } from './models/IRootState';

import Dialog from './components/Dialog.vue';
import DonateExtended from './components/DonateExtended.vue';
import appErrorBlock from './components/shared/error-block/error-block.vue';
import appOverlay from './components/Overlay.vue';
import DonorRescue from './components/DonorRescue.vue';

const VueComponent = Vue.extend({
  components: {
    appDialog: Dialog,
    appDonateExtended: DonateExtended,
    appErrorBlock,
    appOverlay,
    DonorRescue,
  },
  computed: customMapState({
    campaign: (state: IRootState) => state.donation.campaign,
    test: (state: IRootState) => state.donation.donationData.attributes.test,
    config: (state: IRootState) => state.donation.donationParams?.data?.attributes?.config,
    paymentConfig: (state: IRootState) => state.donation.paymentConfig,
    iframe: (state: IRootState) => state.donation.iframe,
    lang: (state: IRootState) => state.translation.locate,
    isDialogVisible: (state: IRootState) => state.donation.dialog.visible,
    fbPixelId(state: IRootState) {
      const { data } = state.donation.donationParams;
      if (data) return data.attributes.config.tracking_fb_pixel_id || null;
      return null;
    },
    fbPixelEventName(state: IRootState) {
      const { data } = state.donation.donationParams;
      if (data) return data.attributes?.config?.tracking_fb_pixel_event_name || 'Purchase';
      return 'Purchase';
    },
    googleAnalyticsId(state: IRootState) {
      const { data } = state.donation.donationParams;
      if (data) return data.attributes.config.tracking_google_analytics_id || null;
      return null;
    },
    orgGoogleAnalyticsId(state: IRootState) {
      const { data } = state.donation.donationParams;
      if (data) return data.attributes.config.tracking_org_google_analytics_id || null;
      return null;
    },
    orgGoogleTagManagerId(state: IRootState) {
      const { data } = state.donation.donationParams;
      if (data) return data.attributes.config.tracking_org_gtm_container || null;
      return null;
    },
    orgGoogleTagManagerSecondId(state: IRootState) {
      const { data } = state.donation.donationParams;
      if (data) return data.attributes.config.tracking_org_gtm_second_container || null;
      return null;
    },
    orgGTMInjectAsText(state: IRootState) {
      const { data } = state.donation.donationParams;
      if (data) return data.attributes.config.tracking_org_gtm_inject_as_text;
      return false;
    },
  }),
})
@Component({})
export default class App extends VueComponent {
  private injectGoogleTagManager = injectGoogleTagManager;

  private isModalVisible: boolean = false;

  private matcherPanel: boolean = true;

  private customView: string = get('custom_view') ? `custom-view-${get('custom_view')}` : '';

  created(): void {
    //  this.$store.dispatch('getCampaign');
    const cid = getCampaignId();
    if (cid.length) {
      document.title = `${document.title} - ${cid}`;
      this.$store.dispatch('getCampaign', { cid });
    } else {
      document.title = `${document.title} - New Campaign`
      this.$store.dispatch('getNewCampaign');
    }

    // Listen to message from iframe element
    window.addEventListener('message', msg => {
      if (msg.data?.MeshulamActiveLoader_nauK1M54J) {
        this.$root.$emit('MeshulamActiveLoader_nauK1M54J', true);
      }

      if (msg.data === 'MeshulamCancelBitPayment_nauK1M54J') {
        this.$store.commit('setDialogVisibleStatus', false);
        this.$store.commit('setIsSubmitted', false);
      }

      if (msg.data && msg.data.type) {
        const sendToParent = (extra: Record<string, unknown> = {}) => {
          const inIframe = window.self !== window.parent;

          if (inIframe) {
            if (msg.data?.payload) {
              msg.data.payload = { ...msg.data.payload, ...extra };
            } else {
              msg.data.payload = extra;
            }
            window.parent.postMessage(msg.data, '*')
          }
        }

        if (msg.data.type === 'gateway-response-success') {
          const state = this.$store.state as IRootState;

          if (state.donation.stepFlag === 3) {
            return
          }

          // Show Success message
          this.$store.commit('setDialogVisibleStatus', false);
          this.$store.commit('setStepFlag', 3);

          const {
            cId, campaignTitle, donationId, realAmount, currency, teams,
            gatewayName, levels,
          } = getDataForAnalytics(state);

          sendToParent({ donationId });

          ga('send', 'event', 'Campaign', 'Donated', campaignTitle, realAmount);
          ga('org.send', 'event', 'Campaign', 'Donated', campaignTitle, realAmount);

          gtag('event', 'campaign_donated', {
            campaign_title: campaignTitle,
            real_amount: realAmount,
          });

          const transaction = {
            id: donationId, // Transaction ID. Required.
            affiliation: `c_${cId} - ${campaignTitle}`,
            revenue: realAmount,
            currency,
          };

          ga('require', 'ecommerce');
          ga('org.require', 'ecommerce');
          ga('ecommerce:addTransaction', transaction);
          ga('org.ecommerce:addTransaction', transaction);
          ga('ecommerce:send');
          ga('org.ecommerce:send');

          gtag('event', 'purchase', {
            currency: transaction.currency.toUpperCase(),
            transaction_id: transaction.id, // Transaction ID.
            affiliation: transaction.affiliation,
            value: transaction.revenue,
          })

          const data = {
            event: 'purchase',
            ecommerce: {
              currencyCode: currency,
              purchase: {
                actionField: {
                  id: donationId,
                  affiliation: `c_${cId} - ${campaignTitle}`,
                  revenue: realAmount,
                },
                products: teams,
                team: teams[0],
                gateway: gatewayName,
                levels,
              },
            },
          };

          window.dataLayer?.push(data);
          window.dataLayer2?.push(data);

          // dataLayer.push({
          //   event: 'purchase',
          //   ecommerce: {
          //     purchase: {
          //       transaction_id: donationId,
          //       affiliation: `c_${cId} - ${campaignTitle}`,
          //       revenue: realAmount,
          //       currency,
          //       items,
          //     },
          //   },
          // });

          if (typeof (fbq) !== 'undefined') {
            fbq('track', this.fbPixelEventName, {
              value: realAmount,
              currency,
            });
          }
          this.$store.commit('setOverlay', false);
        } else if (msg.data.type === 'gateway-response-error') {
          sendToParent()

          this.$store.commit('setDialogIframeSrc', '');
          this.$store.commit('setDialogVisibleStatus', false);

          this.$store.commit('setError', {
            title: 'Error!',
            translationKey: [msg.data?.key, msg.data.msg],
          });

          this.$store.commit('setIsSubmitted', false);
          this.$store.commit('setOverlay', false);
        }
      }
    });
  }

  mounted() {
    const observerOptions = {
      subtree: true, childList: true, attributes: true, characterData: true,
    }
    const observer = new MutationObserver(this.sendHeight);
    observer.observe(this.$refs.main as Node, observerOptions);

    this.sendHeight();
  }

  sendHeight() {
    window.parent.postMessage(
      {
        type: 'offset-height',
        payload: {
          height: (this.$refs.main as any)?.offsetHeight || 0,
        },
      },
      '*',
    );
  }

  @Watch('config')
  configLoaded(): void {
    if (this.config.prohibit_input_characters) {
      window.addEventListener('input', e => {
        const el = e.target;

        if (el instanceof HTMLInputElement && el.type === 'text') {
          const prohibitCharacters = new RegExp(`^(${this.config.prohibit_input_characters})*$`);
          const filterFn = prohibitCharacters.test.bind(prohibitCharacters);

          const newValue = el.value.split('').filter(filterFn).join('');

          if (newValue) {
            el.value = '';
            el.dispatchEvent(new CustomEvent('input'));
          }
        }
      });
    }
  }

  @Watch('campaign')
  campaignChanged(): void {
    this.$store.dispatch('getCampaignParams');
  }

  @Watch('fbPixelId')
  fbPixelTrack(value: null | string): void {
    if (value !== null) {
      fbq('init', this.fbPixelId);
      fbq('track', 'PageView');
    }
  }

  @Watch('googleAnalyticsId')
  googleAnalyticsTrack(value: null | string): void {
    if (value !== null) {
      const fields = getGAClientID();
      ga('create', this.googleAnalyticsId, 'auto', { allowLinker: true, cookieFlags: 'max-age=7200;secure;samesite=none', ...fields });
      ga('send', 'pageview');

      gtag('config', this.googleAnalyticsId);
    }
  }

  @Watch('orgGoogleAnalyticsId')
  googleAnalyticsTrackOrg(value: null | string): void {
    if (value !== null) {
      const fields = getGAClientID();
      ga('create', this.orgGoogleAnalyticsId, 'auto', 'org', { allowLinker: true, cookieFlags: 'max-age=7200;secure;samesite=none', ...fields });
      ga('org.send', 'pageview');

      gtag('config', this.orgGoogleAnalyticsId);
    }
  }

  @Watch('orgGoogleTagManagerId')
  googleTagManagerTrackOrg(id: null | string): void {
    if (id !== null) {
      this.injectGoogleTagManager(id, 'dataLayer', this.orgGTMInjectAsText);
    }
  }

  @Watch('orgGoogleTagManagerSecondId')
  googleTagManagerSecondTrackOrg(id: null | string): void {
    if (id !== null) {
      this.injectGoogleTagManager(id, 'dataLayer2', this.orgGTMInjectAsText);
    }
  }

  gatewayClass(): string {
    if (this.paymentConfig?.id) {
      return `${this.paymentConfig?.attributes?.name}-${this.paymentConfig.id}`
    }
    return ''
  }

  showModal(): void {
    this.isModalVisible = true;
  }

  closeModal(): void {
    this.isModalVisible = false;
  }

  get direction() {
    switch (this.lang) {
      case 'he':
      case 'ar':
        document.dir = 'rtl'
        return 'rtl'
      default:
        document.dir = 'ltr'
        return 'ltr'
    }
  }
}
