var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"success"},[_c('div',{staticClass:"success__ico"},[_c('svg',{attrs:{"viewBox":"0 0 512 512"}},[_c('path',{attrs:{"fill":"#51b748","d":"M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033\n        248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379\n        0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216\n        308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628\n        0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104\n        104c6.249 6.249 16.379 6.249 22.628.001z"}})])]),_c('div',{staticClass:"success__title"},[_vm._v(" "+_vm._s(_vm.$t("success.thank_you"))+" ")]),(_vm.bannerImage || _vm.text)?_c('div',{staticClass:"extra-panel"},[(_vm.bannerImage)?_c('img',{staticClass:"extra-panel__img",attrs:{"src":_vm.bannerImage,"alt":"banner image for donation success page"}}):_vm._e(),(_vm.text)?_c('p',{staticClass:"success__message m-0"},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()]):_vm._e(),(_vm.customMessage)?_c('div',{staticClass:"extra-panel"},[_c('p',{staticClass:"success__message m-0",domProps:{"innerHTML":_vm._s(_vm.$t(
        _vm.customMessage,
        _vm.customMessage,
        {
          ..._vm.customMsgParams,
        }
      ))}})]):_vm._e(),_c('part-of-giving-day-msg'),_c('createPersonalPage'),_c('actionAfterDonation'),(_vm.igulLetovaConfig.value)?_c('igul-letova-panel',{attrs:{"show-zehut":_vm.igulLetovaConfig.show_zehut,"show-issuer":_vm.igulLetovaConfig.show_issuer}}):_vm._e(),(_vm.taxGiftEligible)?_c('tax-gift-extra-panel'):_vm._e(),(_vm.email)?_c('div',{staticClass:"extra-panel"},[_c('p',{staticClass:"extra-panel__description m-0",domProps:{"innerHTML":_vm._s(_vm.$t(
        'success.confirmation_email_sent_to',
        // eslint-disable-next-line max-len
        'You donation amount: {currency_sign}{amount} {currency} with {currency_sign}{tip_amount} {currency} in tips. Confirmation email will be sent to {email}',
        {
          email: _vm.email,
          ..._vm.customMsgParams,
        }
      ))}})]):_vm._e(),_c('div',{staticClass:"extra-panel"},[_c('p',{staticClass:"extra-panel__description m-0",domProps:{"innerHTML":_vm._s(_vm.$t(
        'success.offer_donors_to_create_account',
        // eslint-disable-next-line max-len
        `<a href='https://dashboard.charidy.com/donor-sign-up' target='_blank'>Please create your Charidy Donor Profile to manage your donations and receipts at any time.</a>`,
        {}
      ))}})]),(!_vm.externalLinksHide)?_c('div',{staticClass:"extra-panel"},[_c('h4',{staticClass:"extra-panel__title"},[_vm._v(" "+_vm._s(_vm.$t("success.increase_impact"))+" ")]),_c('p',{staticClass:"extra-panel__description"},[_vm._v(" "+_vm._s(_vm.$t("success.sharing_possibility"))+" ")]),_c('div',{staticClass:"extra-panel__shares"},_vm._l((_vm.shareLinks),function(share,i){return _c('a',{key:share.id+i,staticClass:"extra-panel__link",attrs:{"href":share.social === 'copy_link'
            ? 'javascript:void(0)'
            : _vm.createSharingUrl(share),"target":share.social !== 'copy_link' ? '_blank' : ''},on:{"click":function($event){share.social === 'copy_link'
            ? _vm.showCopy = !_vm.showCopy
            : false}}},[_c('icons',{attrs:{"icon":share.social}})],1)}),0),(_vm.showCopy)?_c('div',{staticClass:"copy-link-box"},[_c('div',{staticClass:"input-group"},[_c('input',{ref:"copyTextInput",staticClass:"form-control",attrs:{"type":"text","autocomplete":"donotdothat"},domProps:{"value":`${_vm.href}`}}),_c('span',{staticClass:"input-group-btn"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){$event.preventDefault();return _vm.onCopy.apply(null, arguments)}}},[_vm._v("Copy")])])])]):_vm._e()]):_vm._e(),_c('invite-friends-to-match-donation'),_c('div',{staticClass:"success__return"},[(_vm.owerwriteLink !== 'hide')?_c('a',{attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.returnToCampaign.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("success.return_to_campaign")))]):_vm._e()]),(_vm.config.show_give_and_get_panel)?_c('give-and-get-extra-panel'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }