
import Vue from 'vue'
import Ifields, { CARD_TYPE, ACH_TYPE, CVV_TYPE } from '@cardknox/vue-cardknox-ifields';
import customMapState from '@/helpers/mapHelper'
import { IRootState } from '@/models/IRootState'

import CardExpDate from '@/components/shared/card-exp-date/card-exp-date.vue'
import { get } from '@/helpers/getUrlParameters';

interface IfieldsAccount {
  xKey: string;
  xSoftwareName: string;
  xSoftwareVersion: string;
}

interface IThreeDS {
  enable3DS: boolean,
  waitForResponse: boolean,
  waitForResponseTimeout: number | undefined,
  amount: number,
  month: number,
  year: number,
}

const buildFunc = (): (value: string | PromiseLike<string>) => void => () => {}

export default Vue.extend({
  components: {
    Ifields,
    CardExpDate,
  },

  inject: ['$validator'],

  data() {
    return {
      bbpos: get('bbpos') === '1', //
      expDate: {
        month: '',
        year: '',
      },
      form: {
        card: {
          isValid: false,
          isEmpty: true,
          ifieldValueChanged: false,
          cardNumberlength: 0,
          length: 0,
          issuer: 'unknown',
        },
        cvv: {
          ifieldValueChanged: false,
          isValid: null,
          isEmpty: true,
          length: 0,
        },
        ach: {
          ifieldValueChanged: false,
          isValid: false,
          isEmpty: true,
          length: 0,
        },
      },
      cardData: {
        cardToken: '',
        cvvToken: '',
      },
      achData: {
        achToken: '',
        routingNumber: '',
      },
      promiseResolveFunc: {
        cardToken: buildFunc(),
        cvvToken: buildFunc(),
        achToken: buildFunc(),
      },
    }
  },

  computed: {
    ...customMapState({
      paymentConfig: (state: IRootState) => state.donation.paymentConfig.attributes.configuration,
      amount: (state: IRootState) => state.donation.donationData.attributes.amount / 100,
      step: (state: IRootState) => state.donation.stepFlag,
    }),

    account(): IfieldsAccount {
      const { ifields_key, software_name, software_version } = this.paymentConfig;
      return {
        xKey: ifields_key,
        xSoftwareName: software_name,
        xSoftwareVersion: software_version,
      }
    },

    type(): 'card' | 'ach' {
      return this.paymentConfig.ifields_type
    },

    isCardType(): boolean {
      return this.type === CARD_TYPE;
    },

    isACHType(): boolean {
      return this.type === ACH_TYPE;
    },

    threeDS(): IThreeDS {
      return {
        enable3DS: false,
        waitForResponse: false,
        waitForResponseTimeout: undefined,
        amount: this.amount,
        month: Number(this.expDate.month),
        year: Number(this.expDate.year),
      };
    },

    isCardInvalid(): boolean {
      const { card } = this.form;
      return !card.isValid && card.length > 8
    },
  },

  watch: {
    step(nv, ov) {
      if (nv === 1 && ov === 2) {
        this.resetState();
        this.clearIfields();
      }
    },
  },

  mounted() {
    this.$root.$on('setDonationGatewayParams', async (resolve: (value?: unknown) => void) => {
      switch (this.type) {
        case CARD_TYPE:
          if (this.form.card.isEmpty) {
            resolve({
              title: 'Form Error!',
              text: this.$t('donation.card_is_empty', 'Card number field is empty'),
            });
            return
          }
          if (!this.form.card.isValid) {
            resolve({
              title: 'Form Error!',
              text: this.$t('donation.card_is_not_valid', 'Card number field is not valid'),
            });
            return
          }
          if (this.form.cvv.isEmpty) {
            resolve({
              title: 'Form Error!',
              text: this.$t('donation.cvv_is_empty', 'CVV field is empty'),
            });
            return
          }
          break;

        case ACH_TYPE:
          if (this.form.ach.isEmpty) {
            resolve({
              title: 'Form Error!',
              text: this.$t('donation.ach_is_empty', 'Account number field is empty'),
            });
            return
          }
          if (!this.form.ach.isValid) {
            resolve({
              title: 'Form Error!',
              text: this.$t('donation.ach_is_not_valid', 'Account number field is not valid'),
            });
            return
          }
          break;

        default:
          break;
      }

      resolve(false)
    });

    this.$root.$on('cardknoxCreateAndSetToken', this.createAndSetToken);
  },

  beforeDestroy() {
    this.$root.$off('cardknoxCreateAndSetToken');
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    async createAndSetToken(next: (value: unknown) => void) {
      if (this.type === CARD_TYPE) {
        const cardRef = this.getRefFromType(CARD_TYPE)
        const cvvRef = this.getRefFromType(CVV_TYPE)

        const cardPromise = new Promise<string>(resolve => {
          this.promiseResolveFunc.cardToken = resolve
        })
        const cvvPromise = new Promise<string>(resolve => {
          this.promiseResolveFunc.cvvToken = resolve
        })

        this.cardData.cardToken = ''
        this.cardData.cvvToken = ''

        cardRef?.getToken()
        cvvRef?.getToken()

        const [tokenRes, cvvRes] = await Promise.all([cardPromise, cvvPromise])

        if (tokenRes.includes('[ERROR]') && !this.bbpos) {
          this.$store.commit('setError', {
            title: 'Cardknox Error!',
            text: 'Card token generation error',
          });
          next(false);
          return
        }

        if (cvvRes.includes('[ERROR]') && !this.bbpos) {
          this.$store.commit('setError', {
            title: 'Cardknox Error!',
            text: 'CVV token generation error',
          });
          next(false);
          return
        }

        this.$store.commit(
          'setDonationIncludedGatewayParams',
          {
            card_token: this.cardData.cardToken,
            cvv_token: this.cardData.cvvToken,
            exp_month: this.expDate.month,
            exp_year: this.expDate.year,
          },
        );

        next(true);
      }

      if (this.type === ACH_TYPE) {
        const achRef = this.getRefFromType(ACH_TYPE)

        const achPromise = new Promise<string>(resolve => {
          this.promiseResolveFunc.achToken = resolve
        })

        achRef?.getToken()

        this.achData.achToken = ''

        const [achRes] = await Promise.all([achPromise])

        if (achRes.includes('[ERROR]')) {
          this.$store.commit('setError', {
            title: 'Cardknox Error!',
            text: 'ACH token generation error',
          });
          next(false);
          return
        }

        this.$store.commit(
          'setDonationIncludedGatewayParams',
          {
            ach_token: this.achData.achToken,
            ach_routing_number: this.achData.routingNumber,
          },
        );

        next(true);
      }

      next(false);
    },

    clearIfields() {
      const cardRef = this.getRefFromType(CARD_TYPE)
      const cvvRef = this.getRefFromType(CVV_TYPE)
      const achRef = this.getRefFromType(ACH_TYPE)

      cardRef?.clearIfield()
      cvvRef?.clearIfield()
      achRef?.clearIfield()
    },

    resetState() {
      this.expDate = {
        month: '',
        year: '',
      }
      this.form = {
        card: {
          isValid: this.bbpos,
          isEmpty: !this.bbpos,
          ifieldValueChanged: false,
          cardNumberlength: 0,
          length: 0,
          issuer: 'unknown',
        },
        cvv: {
          ifieldValueChanged: false,
          isValid: null,
          isEmpty: !this.bbpos,
          length: 0,
        },
        ach: {
          ifieldValueChanged: false,
          isValid: false,
          isEmpty: true,
          length: 0,
        },
      }
      this.cardData = {
        cardToken: '',
        cvvToken: '',
      }
      this.achData = {
        achToken: '',
        routingNumber: '',
      }
    },

    getOptions(field: 'card' | 'ach' | 'cvv') {
      const getPlaceholder = () => {
        switch (field) {
          case CARD_TYPE:
            return this.$t('donation.cc_placeholder', 'Card number')
          case CVV_TYPE:
            return this.$t('donation.cvv_placeholder', 'CVV')
          case ACH_TYPE:
            return this.$t('donation.cvv_placeholder', 'Account number')
          default:
            return '';
        }
      }
      return {
        autoSubmit: true,
        autoFormat: true,
        enableLogging: get('enableLogging') === '1',
        autoFormatSeparator: ' ',
        placeholder: getPlaceholder(),
        iFieldstyle: {
          width: '100%',
          height: '30px',
          outline: 'none',
          fontSize: '14px',
          lineHeight: '1.42857143',
          color: '#555',
          backgroundColor: '#fff',
          backgroundImage: 'none',
          border: '0',
          padding: '0',
          margin: '0',
          display: 'block',
        } as CSSStyleDeclaration,
      }
    },

    getRefFromType(type: 'card' | 'ach' | 'cvv') {
      switch (type) {
        case CARD_TYPE:
          return this.$refs.cardIfield as any;
        case CVV_TYPE:
          return this.$refs.cvvIfield as any;
        case ACH_TYPE:
          return this.$refs.achIfield as any;
        default:
          return null;
      }
    },

    onUpdate(field: 'card' | 'ach' | 'cvv', { data }: any) {
      if (this.bbpos) {
        data.isValid = true;
        data.isEmpty = false;
      }
      this.form[field] = data
    },

    onLoad() {
      this.resetState()
    },

    onError(errorData: any) {
      // eslint-disable-next-line no-console
      console.error(`Cardknox onerror ${JSON.stringify(errorData)}`);

      this.promiseResolveFunc.cardToken('[ERROR]')
      this.promiseResolveFunc.cvvToken('[ERROR]')
      this.promiseResolveFunc.achToken('[ERROR]')
    },

    onCardToken({ data }: any) {
      if (this.form.card.isValid) {
        this.cardData.cardToken = data.xToken
        this.promiseResolveFunc.cardToken(data.xToken)
      } else {
        this.promiseResolveFunc.cardToken('[ERROR]')
        this.cardData.cardToken = ''
      }
    },

    onCvvToken({ data }: any) {
      if (this.form.card.isValid && !this.form.cvv.isEmpty && this.form.cvv.length >= 3) {
        this.cardData.cvvToken = data.xToken
        this.promiseResolveFunc.cvvToken(data.xToken)
      } else {
        this.promiseResolveFunc.cvvToken('[ERROR]')
        this.cardData.cvvToken = ''
      }
    },

    onACHToken({ data }: any) {
      if (this.form.ach.isValid) {
        this.achData.achToken = data.xToken
        this.promiseResolveFunc.achToken(data.xToken)
      } else {
        this.promiseResolveFunc.achToken('[ERROR]')
        this.achData.achToken = ''
      }
    },
  },
})
