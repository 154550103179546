
import Vue from 'vue';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';

import urls from '@/constants/urls';
import { IRootState } from '@/models/IRootState';
import { IGatewayListByCurrency } from '@/models/IGatewayList';
import { getTeamId } from '@/helpers/getUrlParameters';
import { IDonationStreams, ITeamToGatewayConfig } from '@/models/IDonationParams';
import { deepCopy } from '@/helpers/objectHelper';
import { AvailableDisplayCurrencyList } from '@/store/modules/models/donation';
import {
  IDonateWithData,
  ITeamListItem,
  ITeamResponse,
  ISelectedTeam,
} from './subcomponents-models/DonateWith.models';

const VueComponent = Vue.extend({
  components: {
    Multiselect,
  },

  data(): IDonateWithData {
    return {
      team: undefined,
      stream: undefined,
      teamIsLoading: false,
      teamList: [],
      currentOffset: 0,
      selectedTeams: [],
      // Limit and offset of team select
      limitOffset: 20,
      disabledShowMore: false,
      // showError: false,
      showTeamError: {
        empty: false,
        alreadySelected: false,
      },
    };
  },

  computed: {
    ...customMapState({
      multipleTeams: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.DonateToMultipleTeams,
      toAll: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.donate_to_all_teams_btn,
      teamSelectionMandatory: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.donate_team_selection_mandatory,
      streamSelectionMandatory: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.donate_stream_selection_mandatory,
      colorTeamDdlInTeamColor: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.color_team_drop_down_lines_in_team_color,
      donateTeamSkipParent: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.donate_team_skip_parent,
      donateTeamSkipGrandparent: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.donate_team_skip_grandparent,
      donateTeamShowHidden: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.donate_team_show_hidden,
      donateTeamReadonly: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.donate_team_readonly,
      donationStreamsConfig: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.donation_streams_config,
      donateTeamShowDescription: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.donate_team_show_description,
      teamsTotal: (state: IRootState) => state.donation.teamsTotal,
      changeRate: (state: IRootState) => state.donation.changeRate,
      campaignId: (state: IRootState) => state.donation.donationData.attributes.campaign_id,
      streamList: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.donation_streams,
      currencySign: (state: IRootState) => state.donation.donationData.attributes.currency_sign,
      donationCurrency: (state: IRootState) => state.donation.donationData.attributes.currency.toUpperCase(),
      donationAmount: (state: IRootState) => state.donation.donationData.attributes.amount / 100,
      tipAmount: (state: IRootState) => state.donation.donationData.attributes.tip_amount / 100,
      originAmount: (state: IRootState) => state.donation.originAmount,
      stepFlag: (state: IRootState) => state.donation.stepFlag,
      teamReadonlyParam: (state: IRootState) => state.donation.teamReadonly,
      paymentOptionId: (state: IRootState) => state.donation.donationData.attributes.payment_option_id,
      firstSelectedTeam: (state: IRootState) => state.donation.first_selected_team,
      commentLength: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.comment_length || 75,
      preSelectTeams: (state: IRootState) => state.teams.preSelectTeams,
    }),
    ...mapGetters({
      getStreamId: 'getStreamId',
      gatewayList: 'getGatewayListByCurrency',
      useParentTeamGatewayForChildTeam: 'useParentTeamGatewayForChildTeam',
      getMinTeamAmount: 'getMinTeamAmount',
      totalMinTeamsAmount: 'totalMinTeamsAmount',
    }),

    streamListSorted(): IDonationStreams[] {
      const copy = deepCopy(this.streamList)
      return copy.sort((a, b) => a.order - b.order)
    },

    donateToAllTeams: {
      get(): boolean {
        const state = this.$store.state as IRootState
        return state.donation.donationData.attributes.donate_to_all_teams;
      },
      set(value: boolean) {
        if (value) {
          this.selectedTeams = []
          this.addTeam()
        }
        this.$store.commit('setDonateToAllTeams', value)
      },
    },

    teamsTotalAmount(): number {
      const teamsTotalAmount = Math.ceil(this.teamsTotal / (this.changeRate || 1));
      return teamsTotalAmount > this.totalMinTeamsAmount ? teamsTotalAmount : this.totalMinTeamsAmount;
    },

    teamReadonly(): boolean {
      return this.teamReadonlyParam || this.donateTeamReadonly || this.donateToAllTeams;
    },

    gatewayToTeam(): ITeamToGatewayConfig | null {
      return this.$store.getters.gatewayToTeam;
    },

    totalAmount(): number {
      return this.donationAmount + this.tipAmount;
    },

    showAdditionFields(): boolean {
      const team = !!this.selectedTeams[0].team;
      return (this.multipleTeams && team) && this.selectedTeams.length > 1
    },

    campaignCurrency(): AvailableDisplayCurrencyList {
      const state = this.$store.state as IRootState;
      return {
        sign: state.donation.campaign.data.attributes.currency_sign,
        code: state.donation.campaign.data.attributes.currency,
      }
    },

    selectedTeamsLoopKey(): string {
      return this.selectedTeams.reduce((key, el) => {
        let k = key
        if (el.team) {
          k += `team_${el.team.id}`
        }
        return k
      }, '')
    },

    amountIncreased() {
      return this.donationAmount > this.originAmount
    },
  },

  watch: {
    preSelectTeams: {
      handler(ids: string[]) {
        if (ids.length) {
          const [id] = ids;
          this.preSelectTeam(id)
        }
      },
      immediate: true,
    },

    getStreamId: {
      immediate: true,
      handler(id: string) {
        if (id && this.stream && Number(id) !== this.stream.id) {
          this.stream = this.streamList.find(s => s.id === Number(id));
        } else if (id) {
          this.stream = this.streamList.find(s => s.id === Number(id));
        } else {
          this.stream = undefined
        }
      },
    },

    selectedTeams: {
      handler(list: ISelectedTeam[]) {
        this.$store.commit('setTeam', { ...list[0].team });
        this.setData(list);
        setTimeout(() => {
          this.$validator.validate()
        }, 200);
      },
      deep: true,
    },

    originAmount() {
      this.calcAmount('divide');
    },

    stepFlag(newStep, oldStep) {
      if (newStep === 1 && oldStep === 3) {
        this.selectedTeams = [];
        this.addTeam();
      }
    },

    stream(data) {
      const relation = this.donationStreamsConfig?.relation || 'stream_gateway'
      const gateway: IGatewayListByCurrency = this.gatewayList.find(
        (g: IGatewayListByCurrency) => Number(g.id) === data?.gateway_id,
      );
      if (gateway && relation === 'stream_gateway') {
        this.$store.commit('setPaymentConfig', gateway);
        this.$store.commit('setPaymentMethod', gateway.attributes.name);
        this.$store.commit('setStream', { ...data });
      } else if (!gateway || relation !== 'stream_gateway') {
        this.$store.commit('setStream', { ...data });
      } else {
        this.$store.commit('setStream', { id: 0, title: '' });
      }
    },
  },

  created() {
    const id = getTeamId();
    if (id) {
      this.preSelectTeam(id);
    }

    this.addTeam();
    this.showMoreTeams(0);

    const defaultStream = this.streamListSorted.find(e => e.default)
    if (defaultStream) {
      this.stream = defaultStream
    }
  },

  methods: {
    vErrorsHas(field:string, rule: string) {
      return this.vErrors.items.find((el: any) => el.field === field && el.rule === rule);
    },

    messageCounter(msg: string): number {
      return this.commentLength - msg.length;
    },

    preSelectTeam(id: number | string) {
      const params = {
        team_id: id,
        show_hidden: 1,
      }

      axios
        .get<ITeamResponse>(urls.getTeams.replace(':campaignId', this.campaignId), { params })
        .then(response => {
          [this.selectedTeams[0].team] = response.data.data.map(
            (item): ITeamListItem => ({
              id: parseInt(item.id, 10),
              name: item.attributes.name,
              color: item.attributes.color,
              parent_team_id: String(item.attributes.parent_team_id),
              parent_level: item.attributes.parent_level,
              description: item.attributes.description,
            }),
          );
          this.selectedTeams[0].amount = this.donationAmount;
        })
    },

    getColor(team: ITeamListItem) {
      if (this.colorTeamDdlInTeamColor && team && team.color.includes('#')) {
        return team.color;
      }
      return ''
    },

    getName(team: ITeamListItem) {
      const { value, length_limit } = this.donateTeamShowDescription || {}
      const { name, description } = team
      if (value && length_limit !== undefined) {
        const doc = new DOMParser().parseFromString(description, 'text/html')
        const desc = doc.body.innerText.slice(0, length_limit)
        const sufix = length_limit <= description.length ? '...' : ''
        return `${name} <br/> ${desc}${sufix}`
      }
      return name
    },

    setData(list: ISelectedTeam[]) {
      const includeData = list
        .filter(v => v.team && (v.amount > 0))
        .map(({ team, amount, dedication }) => ({
          type: 'donate_to_multiple_teams',
          id: team ? String(team.id) : '0',
          attributes: {
            amount: amount * 100,
            dedication,
            name: team?.name,
          },
        }));

      this.$store.commit(
        'setDonationRelationshipsMultipleTeams',
        includeData.map(({ type, id }) => ({ type, id })),
      );
      this.$store.commit('setDonationIncludedMultipleTeams', includeData);
    },

    closeTeamDropdown() {
      this.currentOffset = 0;
      this.disabledShowMore = false;
      this.showMoreTeams(0);
    },

    searchTeam(value = '', selectAll = false) {
      this.teamIsLoading = true;
      this.disabledShowMore = true;

      const params = {
        q: value,
        skip_parent: Number(this.donateTeamSkipParent) || null,
        skip_grandparent: Number(this.donateTeamSkipGrandparent) || null,
        show_hidden: Number(this.donateTeamShowHidden) || null,
      }

      axios
        .get<ITeamResponse>(
          urls.getTeams.replace(':campaignId', this.campaignId),
          { params },
        )
        .then(response => {
          // Prepare data to multiselect dropdown
          this.teamList = response.data.data.map(
            (item): ITeamListItem => ({
              id: parseInt(item.id, 10),
              name: item.attributes.name,
              color: item.attributes.color,
              parent_team_id: String(item.attributes.parent_team_id),
              parent_level: item.attributes.parent_level,
              description: item.attributes.description,
            }),
          );

          if (selectAll) {
            this.selectedTeams = [];
            this.addTeam();
            this.teamList.forEach((v, i) => {
              this.changeTeam(v, i);
              this.addTeam();
            })
          }

          this.teamIsLoading = false;
        })
        .catch(() => {
          this.teamIsLoading = false;
          this.disabledShowMore = false;
        });
    },

    showMoreTeams(offset: number, selectAll = false) {
      if (offset === 0 && this.teamList.length > 0) this.teamList = [];
      if (this.campaignId === '0') return;

      this.teamIsLoading = true;
      this.disabledShowMore = false;

      this.currentOffset += offset;

      const params = {
        limit: this.limitOffset,
        offset: this.currentOffset,
        skip_parent: Number(this.donateTeamSkipParent) || null,
        skip_grandparent: Number(this.donateTeamSkipGrandparent) || null,
        show_hidden: Number(this.donateTeamShowHidden) || null,
      };

      axios
        .get<ITeamResponse>(
          urls.getTeams.replace(':campaignId', this.campaignId),
          { params },
        )
        .then(response => {
          if (
            response.headers['x-total-teams']
            && +response.headers['x-total-teams']
          ) {
            this.$store.commit(
              'setTeamsTotalAmount',
              +response.headers['x-total-teams'],
            );
          }

          if (
            response.data.data.length < this.limitOffset
            && response.data.data.length >= 0
          ) {
            this.disabledShowMore = true;
          }

          if (response.data.data.length) {
            // Prepare data to multiselect dropdown
            this.teamList = this.teamList.concat(
              response.data.data.map(item => ({
                id: parseInt(item.id, 10),
                name: item.attributes.name,
                color: item.attributes.color,
                parent_team_id: String(item.attributes.parent_team_id),
                parent_level: item.attributes.parent_level,
                description: item.attributes.description,
              })),
            );

            if (selectAll) {
              this.selectedTeams = [];
              this.addTeam();
              this.teamList.forEach((v, i) => {
                this.changeTeam(v, i);
                this.addTeam()
              })
            }
          }
          this.teamIsLoading = false;
        })
        .catch(() => {
          this.teamIsLoading = false;
          this.currentOffset = 0;
          this.disabledShowMore = false;
        });
    },

    // selectAllTeams() {
    //   this.showMoreTeams(0, true);
    // },

    addTeam() {
      let canAdd = true;
      const selectedTeam = {
        team: undefined,
        amount: 0,
        dedication: '',
      }

      if (this.selectedTeams.length === 0) {
        this.selectedTeams.push(selectedTeam);
      } else {
        this.selectedTeams.forEach(v => { if (!v.team) canAdd = false });

        if (canAdd) {
          this.selectedTeams.push(selectedTeam);
        } else {
          this.showTeamError.empty = true;
          this.showTeamError.alreadySelected = false;
        }
      }
    },

    removeTeam(index: number) {
      this.showTeamError.empty = false;
      this.selectedTeams.splice(index, 1);
      this.calcAmount('divide');
    },

    changeTeam(val: any, id: number) {
      const contain = this.selectedTeams
        .findIndex(v => (v.team && val ? v.team.id === val.id : false)) !== -1;

      if (val && !contain) {
        this.showTeamError.empty = false;
        this.selectedTeams[id].team = val;
        this.calcAmount('divide');
      } else {
        this.selectedTeams[id].amount = 0;
        this.selectedTeams[id].dedication = '';
        this.selectedTeams[id].team = undefined;
      }

      if (val && contain) {
        this.showTeamError.alreadySelected = true;
        this.showTeamError.empty = false;
      } else {
        this.showTeamError.alreadySelected = false;
        this.showTeamError.empty = false;
      }
    },

    calcAmount(type: string) {
      switch (type) {
        case 'same':
          this.selectedTeams.map(v => {
            if (v.team) v.amount = this.originAmount
            return v;
          });
          break;

        case 'divide': {
          let cnt = 0;
          this.selectedTeams.forEach(v => { if (v.team) cnt += 1 });
          this.selectedTeams.map(v => {
            const amt = Math.floor(this.originAmount / (cnt || 1));
            if (v.team) v.amount = amt;
            return v;
          });
          break;
        }

        default:
          break;
      }
    },

    isNumber(event: KeyboardEvent): void {
      if (!/\d/.test(event.key)) event.preventDefault();
    },

    getTeamAmoutFielError(i: number): string {
      const selectedCode = this.donationCurrency.toUpperCase();
      const campaignCurrencyCode = this.campaignCurrency.code.toUpperCase();

      const error = (this as any).vErrors.items.find((err: any) => err.field === `team_${i}_amount`);

      if (error?.rule === 'min_value') {
        const minAmount = this.getMinTeamAmount(this.selectedTeams[i])

        return this.$t(
          'donation.team_min_amount_is',
          'Team min amount is {minInSelectedCurrecy}{selectedCurrencyCode}',
          {
            minInSelectedCurrecy: minAmount,
            selectedCurrencyCode: selectedCode,
            campaignCurrencyCode,
          },
        ) as string
      }

      return error?.msg || '';
    },

    getValidateRules(teamData: ISelectedTeam) {
      const minAmount = this.getMinTeamAmount(teamData)

      if (minAmount > 0) {
        return {
          min_value: minAmount,
        }
      }

      return undefined
    },
  },
});
export default class DonateWith extends VueComponent {}
